/**
 * Generates multiple customizable animated sines waves
 * using a canvas element. Supports retina displays and
 * limited mobile support
 *
 * I've created a seperate library based on this pen. 
 */
function extend() {
	var options, name, src, copy, copyIsArray, clone,
		target = arguments[0] || {},
		i = 1,
		length = arguments.length,
		deep = false;
	// Handle a deep copy situation
	if (typeof target === "boolean") {
		deep = target;
		// Skip the boolean and the target
		target = arguments[i] || {};
		i++;
	}

	// Handle case when target is a string or something (possible in deep copy)
	if (typeof target !== "object" && !isFunction(target)) {
		target = {};
	}

	// Extend jQuery itself if only one argument is passed
	if (i === length) {
		target = this;
		i--;
	}

	for (; i < length; i++) {

		// Only deal with non-null/undefined values
		if ((options = arguments[i]) != null) {

			// Extend the base object
			for (name in options) {
				copy = options[name];

				// Prevent Object.prototype pollution
				// Prevent never-ending loop
				if (name === "__proto__" || target === copy) {
					continue;
				}

				// Recurse if we're merging plain objects or arrays
				if (deep && copy && (jQuery.isPlainObject(copy) ||
						(copyIsArray = Array.isArray(copy)))) {
					src = target[name];

					// Ensure proper type for the source value
					if (copyIsArray && !Array.isArray(src)) {
						clone = [];
					} else if (!copyIsArray && !jQuery.isPlainObject(src)) {
						clone = {};
					} else {
						clone = src;
					}
					copyIsArray = false;

					// Never move original objects, clone them
					target[name] = jQuery.extend(deep, clone, copy);

					// Don't bring in undefined values
				} else if (copy !== undefined) {
					target[name] = copy;
				}
			}
		}
	}
	// Return the modified object
	return target;
};

function init(_el,_container) {
	// console.log(_el);
	// console.log(_container.style.backgroundColor);

	_container.style.backgroundColor = "#01AAFF";


	function SineWaveGenerator(options) {
		// $.extend(this, options || {});
		extend(this, options || {});
		// let _this = {
		//     ...this,
		//     ...options
		// }
		if (!this.el) {
			throw "No Canvas Selected";
		}
		this.ctx = this.el.getContext('2d');
		if (!this.waves.length) {
			throw "No waves specified";
		}
		// Internal
		this._resizeWidth();
		window.addEventListener('resize', this._resizeWidth.bind(this));
		// User
		this.resizeEvent();
		window.addEventListener('resize', this.resizeEvent.bind(this));
		if (typeof this.initialize === 'function') {
			this.initialize.call(this);
		}
		// Start the magic
		this.loop();
	}
	// Defaults
	SineWaveGenerator.prototype.speed = 10;
	SineWaveGenerator.prototype.amplitude = 50;
	SineWaveGenerator.prototype.wavelength = 50;
	SineWaveGenerator.prototype.segmentLength = 10;

	SineWaveGenerator.prototype.lineWidth = 2;
	SineWaveGenerator.prototype.strokeStyle = 'rgba(255, 255, 255, 0.2)';

	SineWaveGenerator.prototype.resizeEvent = function() {};

	// fill the screen
	SineWaveGenerator.prototype._resizeWidth = function() {
		this.dpr = window.devicePixelRatio || 1;

		this.width = this.el.width = window.innerWidth * this.dpr;
		this.height = this.el.height = window.innerHeight * this.dpr;
		this.el.style.width = window.innerWidth + 'px';
		this.el.style.height = window.innerHeight + 'px';

		this.waveWidth = this.width * 0.95;
		this.waveLeft = this.width * 0.25;
	}

	SineWaveGenerator.prototype.clear = function() {
		this.ctx.clearRect(0, 0, this.width, this.height);
	}

	SineWaveGenerator.prototype.time = 0;

	SineWaveGenerator.prototype.update = function(time) {
		this.time = this.time - 0.007;
		if (typeof time === 'undefined') {
			time = this.time;
		}

		var index = -1;
		var length = this.waves.length;

		while (++index < length) {
			var timeModifier = this.waves[index].timeModifier || 1;
			this.drawSine(time * timeModifier, this.waves[index]);
		}
		index = void 0;
		length = void 0;
	}

	// Constants
	var PI2 = Math.PI * 2;
	var HALFPI = Math.PI / 2;

	SineWaveGenerator.prototype.ease = function(percent, amplitude) {
		return amplitude * (Math.sin(percent * PI2 - HALFPI) + 1) * 0.5;
	}
	SineWaveGenerator.prototype.drawSine = function(time, options) {
		options = options || {};
		this.amplitude = options.amplitude || this.amplitude;
		this.wavelength = options.wavelength || this.wavelength;
		this.lineWidth = options.lineWidth || this.lineWidth;
		this.strokeStyle = options.strokeStyle || this.strokeStyle;
		this.segmentLength = options.segmentLength || this.segmentLength;

		var x = time;
		var y = 0;
		var amp = this.amplitude;
		// Center the waves
		var yAxis = this.height / 2;
		// Styles
		this.ctx.lineWidth = this.lineWidth * this.dpr;
		this.ctx.strokeStyle = this.strokeStyle;
		this.ctx.lineCap = 'round';
		this.ctx.lineJoin = 'round';
		this.ctx.beginPath();

		// Starting Line
		this.ctx.moveTo(0, yAxis);
		this.ctx.lineTo(this.waveLeft, yAxis);

		for (var i = 0; i < this.waveWidth; i += this.segmentLength) {
			x = (time * this.speed) + (-yAxis + i) / this.wavelength;
			y = Math.sin(x);
			// Easing
			amp = this.ease(i / this.waveWidth, this.amplitude);
			this.ctx.lineTo(i + this.waveLeft, amp * y + yAxis);
			amp = void 0;
		}
		// Ending Line
		this.ctx.lineTo(this.width, yAxis);
		// Stroke it
		this.ctx.stroke();
		// Clean up
		options = void 0;
		this.amplitude = void 0;
		this.wavelength = void 0;
		this.lineWidth = void 0;
		this.strokeStyle = void 0;
		this.segmentLength = void 0;
		x = void 0;
		y = void 0;
	}
	SineWaveGenerator.prototype.loop = function() {
		this.clear();
		this.update();
		window.requestAnimationFrame(this.loop.bind(this));
	}
	return new SineWaveGenerator({
		// el: document.getElementById('waves'),
		el: _el,
		speed: 8,
		waves: [{
			timeModifier: 1,
			lineWidth: 3,
			amplitude: 150,
			wavelength: 200,
			segmentLength: 20,
			//       strokeStyle: 'rgba(255, 255, 255, 0.5)'
		}, {
			timeModifier: 1,
			lineWidth: 2,
			amplitude: 250,
			wavelength: 200,
			//       strokeStyle: 'rgba(255, 255, 255, 0.3)'
		}, {
			timeModifier: 1,
			lineWidth: 3,
			amplitude: -150,
			wavelength: 50,
			segmentLength: 10,
			//       strokeStyle: 'rgba(255, 255, 255, 0.2)'
		}, {
			timeModifier: 1,
			lineWidth: 1,
			amplitude: -100,
			wavelength: 100,
			segmentLength: 20,
			//       strokeStyle: 'rgba(255, 255, 255, 0.1)'
		}],

		initialize: function() {

		},

		resizeEvent: function() {
			var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
			gradient.addColorStop(0, "rgba(254, 255, 255, 0)");
			gradient.addColorStop(0.5, "rgba(255, 255, 255, 1)");
			gradient.addColorStop(1, "rgba(255, 255, 254, 0)");

			var index = -1;
			var length = this.waves.length;
			while (++index < length) {
				this.waves[index].strokeStyle = gradient;
			}

			// Clean Up
			index = void 0;
			length = void 0;
			gradient = void 0;
		}
	});
}

export default init;
