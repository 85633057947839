<template>
	<div ref="commout">
		<canvas ref="waves" id="waves"></canvas>

		<div class="login_kz">
			<div class="login_herder">
				<!-- 框的位置 -->
				<!-- 忘记密码 -->
				<div v-show="isshow === 2" class="login_body_w framodedong">
					<div class="login_nav_up">
						<img class="login_usertouimg" src="../assets/img/logo.png" />
						<div class="login_title_b">{{ setting.login_system_name }}</div>
						<div class="login_verstion">{{ setting.login_system_version }}</div>
						<div class="where_login">忘记密码</div>
						<div class="register_herder_body">
							<div class="login_uaerz">
								<input v-model="register.account" type="text" placeholder="请输入账号" />
								<!-- <img v-show="input.account != ''" @click="userzh" src="../../assets/img/ico/offx.png" /> -->
							</div>
							<div class="login_option">
								<div class="option_left">
									<div :class="input.selectad ? 'op_left_y' : 'op_left_n'" @click="selectoll"></div>
									<div class="op_left_z">七天免登录</div>
								</div>
								<div class="option_right" @click="ontonavgroup(1)">已有账号~~</div>
							</div>
							<div class="login_button_herder" @click="login">
								<div class="button_body">注册</div>
							</div>
						</div>
					</div>
					<div class="login_nav_button">
						<div class="imgleft">
							<img src="../assets/img/login_sic.png" />
						</div>
						<div class="imgright">
							<img src="../assets/img/login_sic.png" />
						</div>
					</div>
				</div>
				<!-- 注册 -->
				<div v-show="isshow === 0" class="login_body_wreg framodedong">
					<div class="login_nav_upreg">
						<img class="login_usertouimg" src="../assets/img/logintou.gif" />
						<div class="login_title_b">{{ setting.login_system_name }}</div>
						<div class="login_verstion">{{ setting.login_system_version }}</div>
						<div class="where_login">注册</div>
						<div class="register_herder_body">
							<div class="login_uaerz">
								<input v-model="register.user_name" type="text" placeholder="请输入用户名" />
								<!-- <img v-show="input.account != ''" @click="userzh" src="../../assets/img/ico/offx.png" /> -->
							</div>
							<div class="login_uaerz">
								<input v-model="register.password" type="text" placeholder="请输入密码" />
							</div>

							<div class="login_uaerz">
								<input v-model="register.passwordtwo" type="text" placeholder="请再次输入密码" />
							</div>

							<div class="login_uaerz">
								<input v-model="register.user_phone" type="text" placeholder="请输入手机号" />
							</div>

							<div class="login_uaerz">
								<input v-model="register.email" type="text" placeholder="请输入邮箱" />
							</div>
							<div class="login_uaerz">
								<input v-model="register.identity_id" type="text" placeholder="你的权限是" />
							</div>
							<div class="login_option">
								<div class="option_left">
									<div :class="input.selectad ? 'op_left_y' : 'op_left_n'" @click="selectoll"></div>
									<div class="op_left_z">七天免登录</div>
								</div>
								<div class="option_right" @click="ontonavgroup(1)">已有账号~~</div>
							</div>
							<div class="login_button_herder" @click="registerfun">
								<div class="button_body">注册</div>
							</div>
						</div>
					</div>
					<div class="login_nav_button">
						<div class="imgleft">
							<img src="../assets/img/login_sic.png" />
						</div>
						<div class="imgright">
							<img src="../assets/img/login_sic.png" />
						</div>
					</div>
				</div>
				<!-- 登录 -->
				<div v-show="isshow === 1" class="login_body_w framodedong">
					<div class="login_nav_up">
						<img class="login_usertouimg" src="../assets/img/logo.png" />
						<div class="login_title_b">{{ setting.login_system_name }}</div>
						<div class="login_verstion">{{ setting.login_system_version }}</div>
						<div class="login_nav_herder">
							<div :class="pitch_on == item.id ? 'login_nav_xua' : 'login_nav_Noxua'"
								v-for="(item, index) in login_manner" :key="index" @click="nav_dataup(item.id)">
								{{ item.title }}
							</div>
						</div>

						<!-- 扫码登录 -->
						<span v-if="pitch_on === 2">
							<div class="login_img_d">
								<img src="../assets/img/img.jpg" />
							</div>
						</span>
						<!-- 验证码登录 -->
						<span v-if="pitch_on === 0">
							<div class="register_herder">
								<div class="login_uaerz">
									<input v-model="input.account" maxlength="13" type="text" placeholder="请输入账号" />
									<img v-show="input.account != ''" @click="userzh"
										src="../assets/img/ico/offx.png" />
								</div>
								<div class="login_verification">
									<input v-model="input.verification" maxlength="8" type="text"
										placeholder="请输入验证码" />
									<div class="login_verification_button">
										<button @click="verification">发送</button>
									</div>
								</div>
								<div class="login_option">
									<div class="option_left">
										<div :class="input.selectad ? 'op_left_y' : 'op_left_n'" @click="selectoll">
										</div>
										<div class="op_left_z">七天免登录</div>
									</div>
									<div class="option_right" @click="ontonavgroup(2)">忘记密码?</div>
									<div class="option_right" @click="ontonavgroup(0)">注册?</div>
								</div>
								<div class="login_button_herder" @click="login">
									<div class="button_body">登录</div>
								</div>
							</div>
						</span>
						<!-- 账号密码登录 -->
						<span v-if="pitch_on === 1">
							<div class="login_uaerz">
								<input v-model="input.account" type="text" placeholder="请输入账号" />
								<img v-show="input.account != ''" @click="userzh" src="../assets/img/ico/offx.png" />
							</div>
							<div class="login_password">
								<input v-model="input.password" :type="ispitch ? 'text' : 'password'"
									placeholder="请输入密码" />
								<img @click="ispitchfun" v-show="ispitch" src="../assets/img/ico/icoyespassword.png" />
								<img @click="ispitchfun" v-show="!ispitch" src="../assets/img/ico/icoNopassword.png" />
							</div>
							<div class="login_code">
								<input maxlength="4" v-model="input.code" type="text" placeholder="请输验证码" />
								<div class="login_codefun" @click="identifying_codefun" v-html="svgdata"></div>
							</div>
							<div class="login_option">
								<div class="option_left">
									<div :class="input.selectad ? 'op_left_y' : 'op_left_n'" @click="selectoll"></div>
									<div class="op_left_z">七天免登录</div>
								</div>
								<!-- 								<div class="option_right" @click="ontonavgroup(2)">忘记密码?</div>
								<div class="option_right" @click="ontonavgroup(0)">注册?</div> -->
							</div>
							<div class="login_button_herder" @click="login">
								<div class="button_body">登录</div>
							</div>
						</span>

					</div>
					<div class="login_nav_button">
						<div class="imgleft">
							<img src="../assets/img/login_sic.png" />
						</div>
						<div class="imgright">
							<img src="../assets/img/login_sic.png" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import init from "./../js/line.js";
	const {
		account_login, //账号登陆
		registerapi, //注册的
		identifying_code, //账号登陆获取验证码
	} = require("../api/login.js")

	export default {
		data() {
			return {
				svgdata: "",
				// 配置
				setting: {
					login_system_name: "\"UGC\" 管理系统", //系统名称
					login_system_version: "v1.0.0", //系统版本
				},
				//对应的倒计时
				Pour_the_meter: 0,
				// 注册的信息
				register: {
					user_name: "",
					verification: "", //对应 输入的验证码的信息
					password: "", //第一次密码
					passwordtwo: "", //第二次密码
					user_phone: "", //手机号码
					email: "",
					identity_id: "", //对应的权限信息
				},
				pitch_on: 1,
				// 登录的方式
				login_manner: [
					// {
					// 	id: 0,
					// 	title: "验证登录",
					// },
					{
						id: 1,
						title: "账号登录",
					},
					// {
					// 	id: 2,
					// 	title: "扫码登录",
					// },
				],
				isshow: 1, //是否显现   0是注册  1 是登录   2 是忘记密码
				ispitch: false, //是否开启密码显示
				input: {
					// 是密码验证的
					code: undefined, //对应 输入的验证码的信
					// 是账号密码的
					// selectad: false, //是否被选中
					account: '', //账号
					password: '', //密码
					svgtext: undefined,
				},
			};
		},
		mounted() {
			init(this.$refs.waves, this.$refs.commout);
		},
		created() {
			this.identifying_codefun()
		},
		methods: {
			// 获取验证码
			async identifying_codefun() {
				let identifying_coderes = await identifying_code();
				this.svgdata = identifying_coderes.data.svgdata;
				this.input.svgtext = identifying_coderes.data.identificationcode;
			},

			// 去各个页面的信息    0是注册  1 是登录  2是忘记密码
			ontonavgroup(id) {
				this.isshow = id
			},
			// 发送验证码信息
			verification() {

			},
			async registerfun() {
				let reqdata = await registerapi(this.register)
				console.log(reqdata)
				if (reqdata.code == 1) {
					this.$message({
						type: 'success',
						message: reqdata.msg,
					})
					this.register = {}
					ontonavgroup(1)

				} else {
					this.register = {}
					this.$message({
						type: 'error',
						message: reqdata.msg,
					})
				}


			},
			// 测试动画按钮
			ceshianniu() {
				this.isshow = this.isshow ? false : true
			},
			//是否被选中
			selectoll() {
				this.input.selectad = this.input.selectad ? false : true;
			},
			//点击登录操作
			async login() {
				let loginresdata = await account_login(this.input);
				if (loginresdata.code === 1) {
					this.$message({
						type: 'success',
						message: loginresdata.msg,
					})
					localStorage.setItem("token", loginresdata.data.token); //存储token
					//把用户信息给vuex
					this.$store.dispatch("setUserInfo", loginresdata.data)
					localStorage.setItem("userInfo", JSON.stringify(loginresdata.data))
					this.$router.push({
						path: '/'
					});
				} else {
					this.identifying_codefun()
					this.$message({
						type: 'error',
						message: loginresdata.msg,
					})

				}


			},
			//对应的登录模式切换
			nav_dataup(id) {
				this.pitch_on = id;
			},
			//清空账户的信息
			userzh() {
				this.input.account = "";
			},
			// 切换密码的按钮
			ispitchfun() {
				this.ispitch = this.ispitch ? false : true;
			},

		}
	}
</script>

<style scoped>
	* {
		padding: 0;
		margin: 0;
	}

	.login_herder {}

	.login_kz {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		/* border: 1px solid #ff0066; */
	}

	.login_body_w {
		width: 380px;
		height: 560px;
		/* 		margin-top: 11%;
		margin-left: 40%; */
		margin: 10% auto;
		border-radius: 10px;
		background-color: #ffffff;
		box-shadow: 1px 1px 10px 1px #dadada;
	}

	.login_body_wreg {
		width: 400px;
		height: 750px;
		margin-top: 7%;
		margin-left: 40%;
		border-radius: 10px;
		background-color: #ffffff;
		box-shadow: 1px 1px 10px 1px #dadada;
	}

	.login_nav_up {
		position: relative;
		width: 90%;
		left: 5%;
		top: 40px;
		height: 400px;
		/* border: 1px solid #ff0066; */
		text-align: center;
	}

	.login_nav_upreg {
		position: relative;
		width: 90%;
		left: 5%;
		top: 40px;
		height: 600px;
		/* border: 1px solid #ff0066; */
		text-align: center;
	}

	.login_usertouimg {
		width: 90px;
		height: 90px;
		border-radius: 50%;
		box-shadow: 1px 1px 10px 1px #efefef;
	}

	.login_title_b {
		color: #2e82f0;
		font-weight: 700;
		font-size: 2.5vh;
	}

	.login_verstion {
		color: #999999;
		font-size: 1.2vh;
	}

	.where_login {
		color: #999999;
		font-size: 1.5vh;
		position: relative;
		top: 5px;
	}

	.input_div_herder {
		width: 100%;
		height: 30px;
	}

	.input_zh {
		width: 100%;
		height: 30px;
		border-top: none;
	}

	.login_uaerz {
		display: flex;
		position: relative;
		top: 20px;
		border-bottom: 1px solid #81b6fe;
	}

	.login_uaerz input {
		width: 87%;
		height: 40px;
		padding-left: 15px;
		/* border: 1px solid #2e82f0; */
		border: none;
	}

	.login_uaerz input:focus {
		outline: none;
		/* color: #ff0066; */
	}

	.login_uaerz img {
		width: 20px;
		height: 20px;
		position: relative;
		top: 10px;
		border-radius: 50%;
	}

	.login_code {
		display: flex;
		position: relative;
		justify-content: space-between;
		top: 35px;
		border-bottom: 1px solid #81b6fe;
	}

	.login_code input {
		width: 45%;
		height: 40px;
		padding-left: 15px;
		/* border: 1px solid #2e82f0; */
		border: none;
	}

	.login_code input:focus {
		outline: none;
		/* color: #ff0066; */
	}

	.login_password {
		display: flex;
		position: relative;
		top: 30px;
		border-bottom: 1px solid #81b6fe;
	}

	.login_password input {
		width: 87%;
		height: 40px;
		padding-left: 15px;
		/* border: 1px solid #2e82f0; */
		border: none;
	}

	.login_password input:focus {
		outline: none;
		/* color: #ff0066; */
	}

	.login_password img {
		width: 20px;
		height: 20px;
		position: relative;
		top: 10px;
		border-radius: 50%;
		cursor: pointer;
	}

	.login_option {
		width: 100%;
		height: 25px;
		position: relative;
		top: 55px;
		display: flex;
		justify-content: space-between;
		/* border: 1px solid #ff0066; */
	}

	.option_left {
		width: 50%;
		height: 60px;
		display: flex;
	}

	.op_left_y {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		border: 1px solid #999999;
		/* background-color: #2e82f0; */
	}

	.op_left_n {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: #2e82f0;
	}

	.option_left .op_left_z {
		position: relative;
		left: 10px;
		top: 0px;
		color: #a6a6a6;
	}

	.option_right {
		color: #f2b848;
		font-weight: 500;
		font-size: 1.5vh;
		cursor: pointer;
	}

	.login_button_herder {
		width: 80%;
		height: 40px;
		position: relative;
		top: 60px;
		left: 10%;
		box-shadow: 1px 1px 10px 1px #cecece;
		background-color: #7eb4fd;
		border-radius: 30px;
	}

	.login_button_herder .button_body {
		color: #eaeaea;
		line-height: 40px;
		font-weight: 600;
		font-size: 1.5vh;
		cursor: pointer;
	}

	.login_nav_button {
		width: 100%;
		height: 100px;
		position: relative;
		top: 60px;
		/* border: 1px solid #ff0066; */
		display: flex;
	}

	.imgleft {
		width: 60%;
		/* border: 1px solid #ff0066; */
	}

	.imgleft img {
		width: 100%;
		height: 100px;
	}

	.imgright {
		width: 40%;
		/* border: 1px solid #ff0066; */
	}

	.imgright img {
		width: 100%;
		height: 100px;
		transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		/* IE 9 */
		-webkit-transform: rotate(180deg);
		/* Safari and Chrome */
	}



	/* --------------------------------------------------------------------------------- */

	.register_herder_wo {
		width: 380px;
		height: 560px;
		margin-top: 11%;
		margin-left: 40%;
		border-radius: 10px;
		background-color: #ffffff;
		box-shadow: 1px 1px 10px 1px #dadada;
	}

	.register_herder_body {
		width: 100%;
		height: 250px;
		/* border: 1px solid #ff0066; */
		position: relative;
		top: 10px;
	}

	.login_nav_herder {
		width: 100%;
		/* border: 1px solid #f06; */
		height: 30px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.login_nav_Noxua {
		width: 90px;
		max-width: 120px;
		color: #999999;
		font-size: 1.3vh;
		/* border: 1px solid #ff0066; */
		position: relative;
		top: 10px;
	}

	.login_nav_xua {
		width: 90px;
		max-width: 80px;
		color: #7eb4fd;
		font-size: 1.7vh;
		/* border: 1px solid #ff0066; */
		position: relative;
		top: 5px;
	}

	.login_img_d {
		width: 220px;
		height: 220px;
		border-radius: 10px;

	}

	.login_img_d img {
		width: 220px;
		height: 220px;
		border-radius: 10px;
		margin-left: 62px;
		margin-top: 10px;

	}

	.register_herder {
		width: 100%;
		height: 220px;
		/* border: 1px solid #ff0066; */
	}



	.framodedong {
		animation-name: framodedongcreate;
		animation-duration: 1s;
	}

	.login_verification {
		display: flex;
		position: relative;
		top: 40px;
		border-bottom: 1px solid #81b6fe;
	}

	.login_verification input {
		width: 60%;
		height: 40px;
		padding-left: 15px;
		/* border: 1px solid #2e82f0; */
		border: none;
	}

	.login_verification input:focus {
		outline: none;
		/* color: #ff0066; */
	}

	.login_verification_button {
		width: 30%;
		height: 40px;
		position: relative;
		top: -5px;
		/* border: 1px solid #ff0066; */
	}

	.login_verification_button button {
		width: 100%;
		height: 100%;
		/* #7eb4fd */
		background-color: #7eb4fd;
		border: none;
		color: #eaeaea;
		border-radius: 10px;
	}



	@keyframes framodedongcreate {
		0% {
			opacity: 0;
			transform: rotateY(-90deg);
			-webkit-transform: rotateY(-90deg);
			/* Safari 与 Chrome */
		}

		100% {
			opacity: 1;
			transform: rotateY(0deg);
			-webkit-transform: rotateY(0deg);
			/* Safari 与 Chrome */
		}
	}



	@keyframes framodedonghua {
		0% {
			opacity: 1;
			transform: rotateY(0deg);
			-webkit-transform: rotateY(0deg);
			/* Safari 与 Chrome */
		}

		50% {
			transform: rotateY(90deg);
			opacity: 0;
			-webkit-transform: rotateY(90deg);
			/* Safari 与 Chrome */
		}

		100% {
			transform: rotateY(180deg);
			-webkit-transform: rotateY(180deg);
			/* Safari 与 Chrome */
		}
	}

	.login_codefun {
		width: 140px;
		height: 45px;
		margin: auto 0;
	}
</style>
