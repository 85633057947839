import request from '@/network/request.js'
/**
 * 执行登录操作
 */
export function account_login(data) {
	return request({
		url: 'admin/login/apilogin',
		method: 'post',
		data,
	})
}

/**
 * 获取账号登陆的验证码
 */

export function identifying_code(data) {
	return request({
		url: '/admin/login/identifying_code',
		method: 'get',
		data,
	})
}
/**
 * 执行注册操作
 */

export function registerapi(data) {
	return request({
		url: '/api/users/register/adminregister',
		method: 'post',
		data,
	})
}
